import {RootStateLocation, RootStateTimeBlock} from "src/store/types";

import {host} from "../components/_common/_constants";
import {SpecialtyId} from "../constants/specialtyIds";

const covidTestingIds = [
  SpecialtyId.COVID_SEROLOGY,
  SpecialtyId.COVID_TESTING_RAPID,
  SpecialtyId.COVID_TESTING_MOBILE,
];

const dayMap = {
  "0": "Su",
  "1": "Mo",
  "2": "Tu",
  "3": "We",
  "4": "Th",
  "5": "Fr",
  "6": "Sa",
};

export const schema = {
  website: "https://carbonhealth.com",
  name: "Carbon Health",
  title: "A fresh take on health care | Carbon Health",
  description: "A new Primary Care location that works with your busy life",
  logo: "https://carbonhealth.com/static/img/_common/logo_200.jpg",
  address: {
    addressCountry: "United States",
    addressLocality: "San Francisco",
    addressRegion: "CA",
    postalCode: "94104",
    streetAddress: "300 California St. 7th Floor",
    email: "support@carbonhealth.com",
    telephone: "+1-415-612-3256",
    latitude: "37.793450",
    longitude: "-122.400340",
  },
};

export const convertHours = (timeBlock: RootStateTimeBlock[]): string => {
  const dayGroupsByRange = timeBlock.groupBy(x => `${x.from}-${x.to}`);
  return dayGroupsByRange
    .getKeys()
    .map(timeRange => {
      // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{ "0": string; "1": string; "2": string; "3": string; "4": string; "5": string; "6": string; }'.
      const days = dayGroupsByRange[timeRange].map(timeBlock => dayMap[timeBlock.day]).join(",");
      return `${days} ${timeRange}`;
    })
    .join(", ");
};

export const getLocationSchemas = (location: RootStateLocation, image: string): string[] => {
  const hourBlock = location.specialties[location.specialtyIds[0]].hours;
  const url = `${host}/locations/${location.slug}`;

  return [
    {
      "@context": "https://schema.org",
      "@type": "MedicalClinic",
      isAcceptingNewPatients: true,
      image,
      address: {
        "@type": "PostalAddress",
        addressLocality: location.address.city,
        addressRegion: location.address.state,
        postalCode: location.address.zip,
        streetAddress: location.address.firstLine,
      },
      description: "",
      name: "Carbon Health",
      openingHours: convertHours(hourBlock),
      telephone: location.phoneNumber,
      url,
      paymentAccepted: "Cash, Credit Card, Insurance",
    },
    covidTestingIds.some(id => location.specialtyIds.includes(id)) && {
      "@context": "https://schema.org",
      "@type": "CovidTestingFacility",
      name: "Carbon Health Urgent Care",
      image,
      address: {
        addressLocality: location.address.city,
        addressRegion: location.address.state,
        postalCode: location.address.zip,
        streetAddress: location.address.firstLine,
        telephone: location.phoneNumber,
        url,
      },
    },
  ]
    .compact()
    .map(s => JSON.stringify(s));
};
