import {compact} from "lodash";

/**
 *
 * @param firstName First name. If missing, will return empty string.
 * @param lastName Last name. If missing, will return empty string.
 * @param credential The credential i.e. 'MD'. Not required.
 * @returns Formatted name i.e. First Last, MD
 */
export const formatName = (firstName?: string, lastName?: string, credential?: string) => {
  if (!firstName || !lastName) return "";
  return compact([compact([firstName, lastName]).join(" "), credential]).join(", ");
};
